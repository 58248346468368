export default [
  {
    path: '/',
    redirect: window.VITE_APP_LOGIN_PAGE || import.meta.env.VITE_APP_LOGIN_PAGE
  },
  {
    path: '/login',
    component: () => import('@/views/login/login.vue')
  },
  {
    path: '/401',
    component: () => import('@/views/error/401.vue')
  },
  {
    path: '/redirect/:path(.*)',
    component: () => import('@/views/redirect/redirect.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/error/404.vue')
  },
  {
    path: '/',
    component: () => import('@/layouts/Layout.vue'),
    children: [
      {
        path: '/index',
        component: () => import('@/views/index/index.vue'),
        meta: {
          title: '工作台'
        }
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/Layout.vue'),
    children: [
      {
        path: '/userInfo/person',
        component: () => import('@/views/userInfo/index.vue'),
        meta: {
          title: '个人中心'
        }
      }
    ]
  }
]
