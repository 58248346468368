<template>
  <router-view />
</template>

<script setup lang="ts">
import { onMounted, nextTick } from 'vue'
import { initStore } from '@/store/index'

onMounted(() => {
  nextTick(() => {
    // 初始化主题样式
    initStore().init('#043e5d')
  })
})
</script>
