import iconfont from './iconfont.vue'

const iconfontPlugin = {
  name: 'iconfont',
  install(app: any): void {
    // 全局挂载
    app.component('iconfont', iconfont)
  }
}

export default iconfontPlugin
