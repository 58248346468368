export const authPermi = (userPermi: string, permiList: string[]) => {
  const allPermi = '*:*:*'
  if (permiList && permiList.length > 0) {
    return permiList.some((v: string) => {
      return v == userPermi || v == allPermi
    })
  } else {
    return false
  }
}

export const authRole = (role: string, roleList: string[]) => {
  const superAdmin = 'admin'
  if (roleList && roleList.length > 0) {
    return roleList.some((v: string) => {
      return v == role || v == superAdmin
    })
  } else {
    return false
  }
}
