import { removeToken } from '@/utils/auth'
import NProgress from 'nprogress'
import { getToken } from '@/utils/auth'
import { userInfoStore } from '@/store/userInfo'
import { permissionStore } from '@/store/permission'
import router from '@/router'
import { authPermi, authRole } from '@/utils/hasAuth'
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import { ResponseData } from '@/interface/userInfo'

NProgress.configure({ showSpinner: false })

const whiteList = ['/', '/login', '/auth-redirect', '/401', '/404', '/register', '/tenant', '/tenant/tlist', '/tenant/tdetails']

router.afterEach((to) => {
  if (to.meta && to.meta.title) {
    window.document.title = to.meta.title || window.VITE_APP_TITLE || import.meta.env.VITE_APP_TITLE
  }
  NProgress.done()
})

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  NProgress.start()
  if (getToken()) {
    if (to.path == '/login') {
      next({ path: '/index' })
      NProgress.done()
    } else {
      if (whiteList.includes(to.path)) {
        next()
        NProgress.done()
      } else {
        const userInfo = userInfoStore()
        if (userInfo.userInfo) {
          next()
        } else {
          userInfo
            .getUserInfo()
            .then((info: ResponseData) => {
              permissionStore()
                .generateRoutes()
                .then((result) => {
                  // console.log(result)

                  if (info.permissions) {
                    authPermi('*:*:*', info.permissions)
                  }
                  if (info.roles) {
                    authRole('admin', info.roles)
                  }
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  result.forEach((element: any) => {
                    if (element.path && !element.path.includes('http')) {
                      router.addRoute(element)
                    }
                  })
                  next({ ...to, replace: true })
                  NProgress.done()
                })
                .catch((err) => {
                  console.error(err, '获取路由信息报错')
                  userInfo
                    .loginOut()
                    .then(() => {
                      removeToken()
                      if (to.query && to.query.redirect) {
                        next({
                          path: window.VITE_APP_LOGIN_PAGE || import.meta.env.VITE_APP_LOGIN_PAGE,
                          query: {
                            redirect: to.query.redirect
                          }
                        })
                      } else {
                        next({
                          path: window.VITE_APP_LOGIN_PAGE || import.meta.env.VITE_APP_LOGIN_PAGE,
                          query: {
                            redirect: to.fullPath
                          }
                        })
                      }
                    })
                    .catch(() => {
                      next({
                        path: window.VITE_APP_LOGIN_PAGE || import.meta.env.VITE_APP_LOGIN_PAGE,
                        query: {
                          redirect: to.fullPath
                        }
                      })
                    })
                })
            })
            .catch((err) => {
              console.error(err, '获取用户信息报错')
              userInfo
                .loginOut()
                .then(() => {
                  if (to.query && to.query.redirect) {
                    next({
                      path: window.VITE_APP_LOGIN_PAGE || import.meta.env.VITE_APP_LOGIN_PAGE,
                      query: {
                        redirect: to.query.redirect
                      }
                    })
                  } else {
                    next({
                      path: window.VITE_APP_LOGIN_PAGE || import.meta.env.VITE_APP_LOGIN_PAGE,
                      query: {
                        redirect: to.fullPath
                      }
                    })
                  }
                })
                .catch(() => {
                  removeToken()
                  next({
                    path: window.VITE_APP_LOGIN_PAGE || import.meta.env.VITE_APP_LOGIN_PAGE,
                    query: {
                      redirect: to.fullPath
                    }
                  })
                })
            })
        }
      }
    }
  } else {
    if (whiteList.includes(to.path)) {
      next()
    } else {
      next({
        path: window.VITE_APP_LOGIN_PAGE || import.meta.env.VITE_APP_LOGIN_PAGE,
        query: {
          redirect: to.fullPath
        }
      })
      NProgress.done()
    }
  }
})
