import { ref } from 'vue'
import { defineStore } from 'pinia'
import { getInfo, logout } from '@/api/login'
import { removeToken } from '@/utils/auth'
import defAva from '@/assets/icons/avatar.svg'
import { ResponseData } from '@/interface/userInfo'

export const userInfoStore = defineStore('userInfo', () => {
  const userInfo = ref()
  const permissions = ref()
  const roles = ref()
  const avatar = ref(defAva)
  const isEditUser = ref()
  const isUserPhotoChange = ref<boolean>(false)
  const getUserInfo = (): Promise<ResponseData> => {
    return new Promise((resolve, reject) => {
      getInfo()
        .then((res: ResponseData) => {
          if (res && res.code == 200) {
            userInfo.value = res.user
            roles.value = res.roles
            permissions.value = res.permissions
            isEditUser.value = res.isEditUser
            if (res.user) {
              avatar.value = res.user.avatar ? (window.VITE_APP_BASE_API || import.meta.env.VITE_APP_BASE_API) + res.user.avatar : defAva
            }
            resolve(res)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
  const setUserPhotoChange = (val: boolean) => {
    isUserPhotoChange.value = val
  }
  const loginOut = (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      logout()
        .then(() => {
          userInfo.value = ''
          roles.value = []
          permissions.value = []
          removeToken()
          resolve(true)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  return {
    userInfo,
    avatar,
    permissions,
    roles,
    isEditUser,
    getUserInfo,
    loginOut,
    isUserPhotoChange,
    setUserPhotoChange
  }
})
