import { ResponseData } from '@/interface/request'
import instance from '@/plugins/request'

// 查询字典数据列表
export function listData(query: string): Promise<ResponseData> {
  return instance.request({
    url: '/system/dict/data/list',
    method: 'get',
    params: query
  })
}

// 查询字典数据详细
export function getData(dictCode: string): Promise<ResponseData> {
  return instance.request({
    url: '/system/dict/data/' + dictCode,
    method: 'get'
  })
}

// 根据字典类型查询字典数据信息
export function getDicts(dictType: string): Promise<ResponseData> {
  return instance.request({
    url: '/system/dict/data/type/' + dictType,
    method: 'get'
  })
}

// 新增字典数据
export function addData(data: string): Promise<ResponseData> {
  return instance.request({
    url: '/system/dict/data',
    method: 'post',
    data: data
  })
}

// 修改字典数据
export function updateData(data: string): Promise<ResponseData> {
  return instance.request({
    url: '/system/dict/data',
    method: 'put',
    data: data
  })
}

// 删除字典数据
export function delData(dictCode: string): Promise<ResponseData> {
  return instance.request({
    url: '/system/dict/data/' + dictCode,
    method: 'delete'
  })
}
