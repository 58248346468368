import { ref } from 'vue'
import { defineStore } from 'pinia'
import { getRouters } from '@/api/login'
import { routerData } from '@/interface/router'
const Layout = import.meta.glob(['../layouts/Layout.vue'])
const ParentView = import.meta.glob(['../layouts/ParentView.vue'])
const InnerLink = import.meta.glob(['../layouts/InnerLink.vue'])
const modules = import.meta.glob('../views/**/*.vue')

export const permissionStore = defineStore('permission', () => {
  const routes = ref<Array<routerData>>([])
  const expandRoutes = ref<Array<string>>([])
  const fastEntryRoutes = ref<Array<routerData>>([])
  const tabRoutes = ref<Array<routerData>>([])
  const mobileFastEntryRoutes = ref<Array<routerData>>([])
  const dynamicRoutes = ref([])
  // 原数据
  const defaultRoutes = ref([])
  const topbarRouters = ref([])
  const sidebarRouters = ref([])
  const filterData = ref()

  const generateRoutes = (): Promise<Array<routerData>> => {
    return new Promise((resolve, reject) => {
      getRouters()
        .then((res) => {
          if (res && res.code == 200) {
            // console.log(res)

            defaultRoutes.value = res.data
            const data: Array<routerData> = filterAsyncRoutes(res.data, '')
            tabRoutes.value = sortFunc(tabRoutes.value, 'orderMobileTab')
            fastEntryRoutes.value = sortFunc(fastEntryRoutes.value, 'orderWebFastEntry')
            mobileFastEntryRoutes.value = sortFunc(mobileFastEntryRoutes.value, 'orderMobileFastEntry')
            routes.value = data
            resolve(data)
          } else {
            reject()
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sortFunc = (data: any[], sortName: any) => {
    return (
      data
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .sort((a: any, b: any): any => {
          if (a.meta && b.meta && b.meta[sortName] && a.meta[sortName]) {
            return a.meta[sortName] - b.meta[sortName]
          } else {
            return
          }
        })
    )
  }

  const filterAsyncRoutes = (data: Array<routerData>, parent: string): Array<routerData> => {
    return (
      data
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .sort((a: any, b: any): any => {
          if (b.orderNum && a.orderNum) {
            return a.orderNum - b.orderNum
          } else {
            return
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .filter((el: routerData | any) => {
          let parentStr: string = parent
          if (parent != '/' && el.path && el.path != '/') {
            if (el.path.substr(0, 1) === '/') {
              parentStr = parent + '' + el.path
            } else {
              parentStr = [parent, el.path].join('/')
            }
          }

          if (el.component) {
            // Layout ParentView InnerLink 组件特殊处理
            if (el.component === 'Layout' || el.component === 'Navigate') {
              el.component = () => Layout['../layouts/Layout.vue']()
            } else if (el.component === 'ParentView') {
              el.component = () => ParentView['../layouts/ParentView.vue']()
            } else if (el.component === 'InnerLink') {
              el.component = () => InnerLink['../layouts/InnerLink.vue']()
            } else {
              if (el.component) {
                el.component = loadView(el.component)
              } else {
                el.component = () => Layout['../layouts/Layout.vue']()
              }
            }
          }

          if (el.meta) {
            el.meta.analyticsIgnore = true
          }

          if (el.meta && el.meta.isExpand && el.meta.isExpand === '0') {
            expandRoutes.value.push(parentStr)
          }
          if (el.meta && el.meta.fastEntry && (el.meta.fastEntry.includes('1') || el.meta.fastEntry === '1,2')) {
            fastEntryRoutes.value.push({ path: parentStr, meta: el.meta })
          }
          if (el.meta && el.meta.fastEntry && (el.meta.fastEntry.includes('2') || el.meta.fastEntry === '1,2')) {
            mobileFastEntryRoutes.value.push({ path: parentStr, meta: el.meta })
          }
          if (el.meta && el.meta.isTab && el.meta.isTab === '0') {
            tabRoutes.value.push({ path: parentStr, meta: el.meta })
          }
          if (el.children != null && el.children && el.children.length > 0) {
            el.children = filterAsyncRoutes(el.children, parentStr)
          }
          return true
        })
    )
  }
  const filterRoutesDetails = (data: Array<routerData>, arr: string[], index = 0) => {
    if (index === arr.length - 1 && !index) {
      for (let i = 0; i < data.length; i++) {
        const element = data[i]
        if (element.path && element.path.includes(arr[index])) {
          filterData.value = element
          break
        }
      }
    } else {
      for (let i = 0; i < data.length; i++) {
        const element = data[i]
        if (element.path && element.path.includes(arr[index])) {
          filterData.value = element
          break
        }
      }
      if (filterData.value && filterData.value.children && filterData.value.children.length) {
        filterRoutesDetails(filterData.value.children, arr, ++index)
      }
    }
  }

  const getRoutesDetails = (str: string) => {
    let arr: string[]
    if (str.includes('/')) {
      arr = str.split('/').splice(1)
    } else {
      arr = [str]
    }
    filterRoutesDetails(routes.value, arr)
    return filterData.value
  }
  const filterCurrentRoutes = (data: Array<routerData>, str: string) => {
    data.forEach((el) => {
      if (str && el.name) {
        if (el.name?.toLowerCase() === str?.toLowerCase()) {
          filterData.value = el
        }
      } else {
        if (el.path === '/' && el.children && el.children.length) {
          filterCurrentRoutes(el.children, str)
        }
      }
    })
  }

  const getCurrentRoutes = (str: string) => {
    if (str === 'index') {
      filterData.value = {
        name: 'index',
        path: '/index'
      }
    } else {
      filterCurrentRoutes(routes.value, str)
      return filterData.value
    }
  }

  const loadView = (view: string) => {
    let res
    for (const path in modules) {
      const dir = path.split('views/')[1].split('.vue')[0]

      if (dir == view) {
        res = () => modules[path]()
      }
    }

    return res
  }

  // // 过滤当前用户的操作权限

  // const filterOperationRoutes = (routes: RouteRecordRaw) => {
  //   return filterOperationRoutes
  // }

  return {
    routes,
    expandRoutes,
    fastEntryRoutes,
    tabRoutes,
    mobileFastEntryRoutes,
    dynamicRoutes,
    defaultRoutes,
    topbarRouters,
    sidebarRouters,
    generateRoutes,
    getRoutesDetails,
    getCurrentRoutes
  }
})
