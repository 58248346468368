import { createWebHashHistory, createRouter } from 'vue-router'
// import { afterEach, beforeEach } from '@/utils/permission'
import routes from './static'
const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})
export default router
