<template>
  <div class="my-xs 2xl:my-md">
    <a-space direction="vertical">
      <a-breadcrumb>
        <a-breadcrumb-item
          class="cursor-pointer"
          v-if="props.return"
          @click="$router.back()"
          ><i class="text-14 mr-xxs iconfont icon-icon_arrow-left"></i><span>返回</span></a-breadcrumb-item
        >
        <a-breadcrumb-item
          v-for="(item, index) in list"
          :key="index"
          ><div v-if="item.path != props.path">
            {{ item.title }}
          </div>
          <slot v-else></slot>
        </a-breadcrumb-item>
      </a-breadcrumb>
    </a-space>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { routerData } from '@/interface/router'
import { permissionStore } from '@/store/permission'

const route = useRoute()
const permission = permissionStore()
const list = ref<routerData>()
const props = defineProps({
  path: {
    type: String,
    default: ''
  },
  return: {
    type: Boolean,
    default: false
  }
})

const initPage = () => {
  let location = route.path.split('/')
  let str = ''
  let index = 0
  location[index] ? (str = location[index]) : (str = location[++index])
  const data = permission.getCurrentRoutes(str)
  list.value = concatObj(data, location, index)
}

const concatObj = (data: routerData, location: string[], i: number) => {
  const lst = []
  if (data && data.name && data.name?.toLowerCase() == location[i].toLowerCase()) {
    lst.push({ path: data.path?.startsWith('/') ? data.path : '/' + data.path, title: data.meta?.title, icon: data.meta?.icon, color: data.meta?.color })
  }
  if (data && data.children && data.children.length > 0) {
    const arr = enumableData(data.children, data.path?.startsWith('/') ? data.path : '/' + data.path, location, ++i)
    lst.push(...arr)
  }
  return lst
}

const enumableData = (data: routerData[], path: string | undefined, location: string[], i: number): routerData[] => {
  const arr:
    | routerData[]
    | {
        path: string
        title: string | undefined
        icon: string | undefined
        color: string | undefined
      }[] = []
  data.forEach((el: routerData) => {
    if (el && el.name && el.name?.toLowerCase() == location[i].toLowerCase()) {
      arr.push({ path: [path, el.path].join('/'), title: el.meta?.title, icon: el.meta?.icon, color: el.meta?.color })
    }
    if (el.children && el.children.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const lis: any = enumableData(el.children, path, location, ++i)
      arr.push(...lis)
    }
  })
  return arr
}

initPage()
</script>
